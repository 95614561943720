import "./styles.scss"

import React, { useState } from "react"
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import { withPrefix } from "gatsby"

import t from "locale"

const containerStyle = {
  width: "100%",
  height: "675px",
}

const center = {
  lat: 50.07071315,
  lng: 19.93385893,
}

const mapStyle = [
  {
    featureType: "all",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#e7f3ff",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#e2e9f0",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#bad6f2",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#f2f2f2",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#e9e9e9",
      },
      {
        lightness: 17,
      },
    ],
  },
]

const Map = ({ lang }) => {
  const [infoWindow, setInfoWindow] = useState(true)

  return (
    <div className="google-map">
      <LoadScript googleMapsApiKey="AIzaSyB3C-uaPi6METM7qhECAYBB6RESqdvLggE">
        <GoogleMap
          center={center}
          zoom={14}
          options={{ styles: mapStyle }}
          mapContainerStyle={containerStyle}
        >
          <Marker
            icon={withPrefix("/ico-pointer.png")}
            position={{
              lat: 50.07071315,
              lng: 19.93385893,
            }}
            onClick={() => setInfoWindow(true)}
          >
            {infoWindow && (
              <InfoWindow
                onCloseClick={() => setInfoWindow(false)}
                position={{
                  lat: 50.07141315,
                  lng: 19.93385893,
                }}
              >
                <div id="map-tooltip">
                  <p>Alingua Sp. z o.o.</p>
                  <ul>
                    <li>ul. Szlak 10/5 ({t("2 piętro", lang)})</li>
                    <li>31-161 {t("Kraków", lang)}</li>
                    <li>
                      <a href="tel:+48 884 004 934">+48 884 004 934</a>
                    </li>
                  </ul>
                  <p>
                    <strong>{t("Godziny otwarcia", lang)}</strong>
                  </p>
                  <p>{t("Poniedziałek-Piątek: 8:00 - 17:00", lang)}</p>
                </div>
              </InfoWindow>
            )}
          </Marker>
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default Map
