import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Map from "components/Map"
import { Main } from "page_components/contact"

const Contact = ({ pageContext }) => {
  const dataAcf = pageContext?.data?.acfContact
  const lang = pageContext.data.language.slug

  return (
    <Layout
      seo={{
        title: pageContext?.title,
        description: pageContext?.data?.seo?.metaDesc,
      }}
      lang={lang}
      translations={pageContext.data.translations}
    >
      <PageHeader title={pageContext?.title} />
      <Main data={dataAcf} />
      <Map lang={lang} />
    </Layout>
  )
}
export default Contact
